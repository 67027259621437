@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

html,
body {
  animation: onLoad 2s;
  width: 100%;
  overflow-x: hidden;
}
#root {
  height: 100%;
  width: 100%;
}

.gradient-section {
  height: 70vh;
  width: 100%;
  position: absolute;
  top: 0%;
  z-index: -1;
}

@media only screen and (max-width: 1050px) {
    
  .gradient-section {
    height: 80vh;
  }
}



