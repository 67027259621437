.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

.header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    height: 75px;
    z-index: 9999;
    background-color: transparent;
}

.links {
    color: #202429;
    font-family: 'Mulish', sans-serif;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: bold;
    
}

.resume-circle {
    transition: all .1s ease-in-out;
}

.resume-container {
    overflow: visible;
}

.resume:hover .resume-circle {
    fill: #3993F9;
    transform: scale(1.1);
}

.rect {
    transition: all .1s ease-in-out;
}

.resume:hover .rect {
    transform: scale(1.1);
}

.resume {
    display: flex;
    align-items: center;
    margin-left: 50px;
    transition: all .1s ease-in-out;
    opacity: 0;
    animation: fade-in .3s .5s ease-in-out forwards;
}

.github-circle {
    transition: all .1s ease-in-out;
}

.github-container {
    overflow: visible;
}

.github:hover .github-circle {
    fill: #3336EF;
    transform: scale(1.1);
}

.github {
    display: flex;
    align-items: center;
    margin-left: 50px;
    opacity: 0;
    transition: all .1s ease-in-out;
    animation: fade-in .3s .7s ease-in-out forwards;
}

.linked-circle {
    transition: all .1s ease-in-out;
}

.linked-container {
    overflow: visible;
}

.linked:hover .linked-circle {
    fill: #02158A;
    transform: scale(1.1);
}

.linked {
    display: flex;
    align-items: center;
    margin-left: 50px;
    opacity: 0;
    transition: all .1s ease-in-out;
    animation: fade-in .3s .9s ease-in-out forwards;
}

@media only screen and (max-width: 660px) {
    .links {
    justify-content: center;
    }

    .resume, .github, .linked {
    margin-left: 25px;
    margin-right: 25px;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}