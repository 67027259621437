.bio-block {
    border-top: 1px solid #eeeeee;
    display: flex;
    flex-direction: row; 
    width: 100%;
    min-height: 700px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fafafa;
}


.social-button-image{
    max-width: 35px;
}

.social-button {
    width: 250px;
    height: 50px;
    border-radius: 300px;
    background-image: linear-gradient(to right, #3993F9, #02158A);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: transform .2s ease-in-out;
}

.social-button:hover {
    transform: scale(1.05)
}

.social-button-text {
    color: white;
    font-family: 'Mulish', sans-serif;
    margin-left: 5px;
    margin-right: 10px;
}

.social-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.name-div {
    max-width: 700px;

}

.name-section {
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
    color: #242528;
    margin-bottom: 20px;
    font-size: 64px;
    text-align: center;
}

.bio-section {
    transition: opacity .5s .3s  ease-out, transform .5s .3s ease-out;
}

.about {
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
    color: #242528;
    margin-bottom: 20px;
    font-size: 48px;
    text-align: center;
    
}

.bio {
    color: #242528;
    line-height: 2.1;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 500px;
    align-self: center;
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    text-align: center;
}

@media only screen and (max-width: 720px) {
    .name-section {
        font-size: 1rem;
    }
    
}

@media only screen and (max-width: 550px) {
   
    .bio {
        max-width: 300px;
    }

    .bio-section {
        margin-top: 35px;
        margin-bottom: 50px;
    }
    
}