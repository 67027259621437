footer {
    height: 100px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copyright {
    margin-top: 10px;
    color: #242528;
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
    font-size: 2rem;
    text-align: center;
}

.reserved {
    color: #242528;
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    font-size: 11px;
    text-align: center;
}