.projects {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-image: linear-gradient(to right, #3993F9, #02158A);
}

.project-header {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
}

.project-headline {
    font-family: 'Mulish', sans-serif;
    font-size: 3rem;
    font-weight: 900;
    color: #fafafa;
    transition: opacity .5s .3s  ease-out, transform .5s .3s ease-out;
}

.project-title {
    font-size: 2rem;
    font-weight: 200;
    font-family: 'Mulish', sans-serif;
    color: #fafafa;
    margin-bottom: 35px;
}

.project {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 35px;
    margin-bottom: 75px;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    transition: opacity .5s .7s  ease-out, transform .5s .7s ease-out;
}

.project-reverse {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 35px;
    margin-bottom: 100px;
    flex-wrap: wrap-reverse;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    transition: opacity .5s .7s  ease-out, transform .5s .7s ease-out;
}

.project-technologies {
    margin-bottom: 35px;
}

.project-technologies svg {
    height: 50px;
    margin-right: 25px;
}

.project-description {
    max-width: 500px;
    margin-bottom: 35px;
    line-height: 2.1;
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Mulish', sans-serif;
    color: #fafafa;
}

.project-button:hover {
    transform: scale(1.05)
}

.project-button-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.project-links {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
}

.project-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Mulish', sans-serif;
    margin-right: 35px;
    height: 50px;
    cursor: pointer;
    width: 200px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 300px;
    transition: transform .2s ease-in-out;
}

.project-button-title {
    color: #3993F9;
    font-weight: 600;
}

.project-video {
    width: 500px;
    height: fit-content;
    overflow: hidden;
    border-radius: 15px;
}

.project-list {
    width: 100%;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1150px) { 
    .project {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        width: 100%;
    }
    
    .project-reverse {
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
        max-width: 100%;
        width: 100%;
    }

    .project-title {
        text-align: center;
    }

    .project-description {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .project-technologies {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .project-button {
        margin-left: 25px;
        margin-right: 25px;
    }

    .project-links {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 550px) { 
    .project-video {
        max-width: 300px;
    }

    video {
        max-width: 300px;
    }

    .project-info {
        width: 100%;
    }

    .project-button {
        margin-left: 10px;
        margin-right: 10px;
    }

    .project-links {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .project-list {
        margin-bottom: 0px;
    }

    .project-header {
        padding-bottom: 10px;
    }

    .project-description {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
