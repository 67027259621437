.name-section {
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
    color: #242528;
    margin-bottom: 10px;
    font-size: 4rem;
    text-align: center;
}

.certification {
    max-height: 100px;
}

.certifications {
    display: flex;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.title {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    color: #c4c4c4;
    font-size: .9rem;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.shadow-circle {
    width: 410px;
    height: 410px;
    border-radius: 300px;
    top: 30px;
    position: absolute;
    background-image: linear-gradient(to right, #3993F9, #02158A);
    z-index: -1;
    animation: breathe 1s ease-in-out alternate infinite;
}

@keyframes breathe {
    from {
       transform: rotate(0);
    }
    to {
       transform: rotate(360deg);
    }
}

.bio-pic {
    max-width: 400px;
    border-radius: 300px;
    margin-bottom: 35px;
    margin-top: 35px;
}

.picture {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: opacity .5s .3s  ease-out, transform .5s .3s ease-out;
}


.picture-block {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #242528 20%, white 20%);
    align-items: center;
    align-self: center;
    flex-direction: column;
}

#image {
    width: 100%;
    max-height: 100vh;
    position: absolute;
    z-index: -1;
}

@media only screen and (max-width: 660px) { 

    .name-section {
        margin-bottom: 35px;
    }

    .bio-pic {
        max-width: 300px;
    }

    .picture-block {
        background: linear-gradient(110deg, #242528 10%, white 10%);
    }

    .shadow-circle {
        width: 310px;
        height: 310px;

    }

}
